import { Project, Frame, Organization, CustomAppearance } from '@/types'
import { toRaw, Ref } from 'vue'
import { cloneDeep, pick } from 'lodash'
import { RealtimeChannel, User } from '@supabase/supabase-js'
import supabase from '@/supabase'
import { getUser } from '@/auth'
import { FeatureFlags } from '@/featureFlags'
import { TypedEmitter } from 'tiny-typed-emitter'

export const dbUpdateTicker = new TypedEmitter<{
  dbUpdate:(projectId: string) => void,
  orgDbUpdate: () => void
}>()

export function getCurrentTimestamp () {
  return new Date()
}

export function cleanProject (project: Project) {
  const projectCopy: Pick<Project, 'frames' | 'characters' | 'scenes' | 'name'> = pick(cloneDeep(toRaw(project)), ['frames', 'characters', 'scenes', 'name'])

  projectCopy.frames.forEach((frame) => {
    frame.frameOptions?.forEach(fo => {
      delete fo.inpaint
      delete fo.skeletonPoints
      delete fo.crop
    })

    delete frame.editorImage
    delete frame.editorVideo
    delete frame.videoPayload
    delete frame.openAIPrompt
    delete frame.generationProgress
    delete frame.videoInProgress
    delete frame.imageInProgress
    delete frame.inpaintInProgress
    delete frame.styleTransferInProgress
  })
  return projectCopy
}

export async function commitProject (project: Project, lastUpdated: Date) {
  const projectCopy = cleanProject(project)

  const { error } = await supabase.from('projects').update({ ...projectCopy, lastUpdated }).eq('id', project.id)

  if (error) {
    console.log(error)
    throw new Error('Error updating project')
  }
}

export async function deleteCustomAppearance (appearanceId: string) {
  const { error } = await supabase.from('custom_appearances').delete().eq('id', appearanceId)

  if (error) {
    console.log(error)
    throw new Error('Error deleting character')
  }
}

async function getOrgId (user: User) {
  // retreive the user document from supabase

  const { data, error } = await supabase
    .from('users')
    .select()
    .eq('email', user.email)

  if (error) {
    console.log(error)
    throw new Error('Error fetching user')
  }

  if (!data) {
    throw new Error('User not found')
  }
  return data[0].orgId
}

export async function getOrg (user: User) {
  // retreive the organization document from supabase
  const { data } = await supabase
    .from('organizations')
    .select(' *, users!inner(email, survey_answers)')
    .eq('users.email', user.email)
    .single()

  if (!data) {
    throw new Error('Org not found')
  }

  return data as Organization
}

export function subscribeToOrgChanges (orgId: string, org: Ref<Organization | null>): RealtimeChannel {
  return supabase
    .channel('org-changes')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'organizations',
        filter: 'id=eq.' + orgId
      },
      (payload) => {
        org.value = payload.new as Organization
      }
    )
    .subscribe()
}

export async function getCustomAppearances (): Promise<CustomAppearance[]> {
  const user = await getUser()
  const orgid = await getOrgId(user)
  const { data, error } = await supabase.from('custom_appearances').select('*').eq('owner_id', orgid).order('created')
  if (error) {
    console.log(error)
    throw new Error('Error fetching custom appearances')
  }

  return data.map(customAppearance => {
    return {
      description: customAppearance.description,
      extractedImage: customAppearance.extracted_image,
      id: customAppearance.id,
      name: customAppearance.name,
      style: customAppearance.style
    }
  })
}

export async function getProjects (projectId: null | string = null): Promise<Project[]> {
  const user = await getUser()
  const orgid = await getOrgId(user)

  let partialQuery = supabase.from('projects').select('*')

  if (projectId) {
    partialQuery = partialQuery.eq('id', projectId)
  } else {
    partialQuery = partialQuery.eq('deleted', false).eq('ownerUID', orgid)
  }

  const { data, error } = await partialQuery.order('lastUpdated', { ascending: false })

  if (error) {
    console.log(error)
    throw new Error('Error fetching projects')
  }

  const projects: Project[] = []
  data!.forEach((doc: Project) => {
    doc.frames.forEach((frame) => {
      frame.generationProgress = 'DONE'
      frame.videoInProgress = false
      frame.inpaintInProgress = false
      frame.imageInProgress = false
      frame.styleTransferInProgress = false

      if (!frame.frameOptions) {
        frame.frameOptions = []
      }
      frame.frameOptions.forEach((frameOption) => {
        if (!frameOption.id) {
          frameOption.id = Math.random().toString(36).substring(7)
        }
      })

      if (!frame.videoOptions) {
        frame.videoOptions = []
      }

      frame.editorImage = frame.image
      frame.openAIPrompt = frame.frameOptions.find((fo) => fo.image === frame.image)?.payload
      frame.editorVideo = frame.video
      frame.videoPayload = frame.videoOptions.find((vo) => vo.url === frame.video)?.payload

      if (frame.openAIPrompt && !frame.openAIPrompt.scene) {
        frame.openAIPrompt!.scene = (frame as Frame & { scene: string }).scene
      }
    })

    projects.push({
      characters: [],
      scenes: [],
      ...doc
    })
  })

  projects.sort((a, b) => {
    return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime()
  })
  return projects
}

export function subscribeToProjectChanges (projectId: string, projects: Ref<Project[] | null>): RealtimeChannel {
  return supabase
    .channel('project-changes')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'projects',
        filter: 'id=eq.' + projectId
      },
      (payload) => {
        const change = payload.new
        console.debug('subscription: received payload', payload)

        const project = projects.value!.find((p) => p.id === change.id)!
        const currentProjectLastUpdated = new Date(project.lastUpdated).getTime()
        const changeLastUpdated = new Date(change.lastUpdated).getTime()

        if (currentProjectLastUpdated > changeLastUpdated) {
          console.debug('subscription: stale data', change.id)
          return
        }

        if (currentProjectLastUpdated === changeLastUpdated) {
          dbUpdateTicker.emit('dbUpdate', change.id)
          console.debug('subscription: bounce', change.id)
          return
        }

        console.debug('subscription: updating project')

        projects.value = projects.value!.map((p) => {
          if (p.id === change.id) {
            change.frames.forEach((frame: Frame) => {
              const frameToUpdate = p.frames.find((f) => f.id === frame.id)

              frame.editorImage = frameToUpdate!.editorImage ?? frame.image
              frame.openAIPrompt = frameToUpdate!.openAIPrompt
              frame.videoPayload = frameToUpdate!.videoPayload
              frame.editorVideo = frameToUpdate!.editorVideo
            })
            return change as Project
          }
          return p
        })
      }
    )
    .subscribe()
}

export async function deleteProject (projectId: string): Promise<void> {
  await supabase.from('projects').update({ deleted: true }).eq('id', projectId)
}

export async function getGlobalFeatures (): Promise<FeatureFlags> {
  const { data, error } = await supabase.from('features').select('*')

  if (error) {
    throw new Error('Error fetching global features' + error.message)
  }

  return data.reduce((acc, feature) => {
    acc[feature.name] = feature.enabled
    return acc
  }, {}) as FeatureFlags
}
