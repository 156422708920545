import axios from 'axios'
import { getAuthToken } from '@/auth'

export async function getAxios (authorization = true) {
  const accessToken = await getAuthToken()
  return axios.create(authorization
    ? {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    : undefined)
}
