<template>
  <div
    v-if="done"
    class="card"
  >
    <div class="title">
      Nice! Your invitation is on its way to {{ receiverEmail }}.
    </div>
    <KatalistButton
      class="primary mt-4 mr-4"
      color="#FF9ECF"
      @click="resetForm"
    >
      Invite someone else
    </KatalistButton>
    <KatalistButton
      class="mt-4"
      color="#CCC"
      @click="emit('close')"
    >
      Done
    </KatalistButton>
  </div>
  <div
    v-else
    class="card"
  >
    <div class="title">
      Don’t let your colleagues miss out!
    </div>
    <div class="content">
      <p>Let them know you’re using Katalist Storytelling Studio so they can upgrade their AI toolbelts too.</p>

      <p class="pt-4 pb-2 font-weight-bold">
        How should we call you?
      </p>
      <v-text-field
        v-model="senderName"
        placeholder="Your Name or Nickname"
        outlined
        hide-details
        variant="outlined"
      />
      <p class="pt-4 pb-2 font-weight-bold">
        What is your colleague's email?
      </p>
      <v-text-field
        v-model="receiverEmail"
        placeholder="Invitee Email"
        hide-details
        variant="outlined"
        @keydown.enter="inviteFriend"
      />
    </div>
    <div class="error">
      {{ errorMsg }}
    </div>
    <KatalistButton
      :disabled="!enabled"
      class="primary mt-4"
      color="#FF9ECF"
      :loading="loading"
      @click.prevent="inviteFriend"
    >
      Send Invite
    </KatalistButton>
  </div>
</template>

<script setup lang="ts">
import KatalistButton from '@/components/KatalistButton.vue'
import { getAxios } from '@/services/axiosHelper'
import tracking from '@/services/tracking'
import { onMounted, ref, computed } from 'vue'

const senderName = ref('')
const receiverEmail = ref('')
const done = ref(false)
const loading = ref(false)
const errorMsg = ref('')

const emit = defineEmits(['close'])

const enabled = computed(() => {
  return senderName.value !== '' && receiverEmail.value !== ''
})

onMounted(() => {
  tracking.track('Referral Card Opened')
})

const resetForm = () => {
  receiverEmail.value = ''
  done.value = false
  loading.value = false
}

const inviteFriend = async () => {
  tracking.track('Friend Invited')
  loading.value = true
  const axios = await getAxios()

  try {
    await axios.post(
      '/api/referral',
      {
        senderName: senderName.value,
        receiverEmail: receiverEmail.value
      }
    )
    done.value = true
  } catch (e: any) {
    if (e.response.status === 429) {
      errorMsg.value = 'Slow down cowboy. You\'re sending too many requests.'
    } else {
      console.error(e)
      errorMsg.value = 'Unknown error, please retry.'
    }
  }
  loading.value = false
}

</script>

<style scoped lang="scss">
.card {
  text-align: center;
  padding: 1rem;
  color: #111;
  background-color: #FCFCFC;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 8px;
  border-width: 5px;
  border-color: #FF9ECF;
  border-style: solid;
}

.title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.error {
  color: red;
}
</style>
