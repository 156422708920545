<template>
  <v-btn
    class="text-none katalist-button"
    :loading="loading"
    variant="outlined"
    color="#111111"
    :style="buttonStyle"
    :disabled="disabled"
    @click="onClick"
  >
    <template
      v-if="spinnerColor"
      #loader
    >
      <v-progress-circular
        indeterminate
        :color="spinnerColor"
        :width="3"
      />
    </template>
    <div class="katalist-button-text">
      <slot />
    </div>
  </v-btn>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: String,
  loading: Boolean,
  onClick: Function,
  disabled: Boolean,
  borderColor: {
    type: String,
    default: 'transparent'
  },
  spinnerColor: {
    type: String,
    default: undefined
  },
  fontWeight: {
    type: String,
    default: 'normal'
  },
  borderWidth: {
    type: String,
    default: '1px'
  },
  shadowColor: {
    type: String,
    default: 'transparent'
  },
  height: {
    type: String,
    default: '46px'
  }
})

const buttonStyle = computed(() => ({
  background: props.disabled ? 'rgba(197, 195, 195, 1) !important' : props.color,
  opacity: '1',
  height: props.height
}))

</script>

<style scoped lang="scss">
.katalist-button {
  &:hover {
    filter: brightness(110%);
  }

  box-shadow: 5px 5px v-bind(shadowColor)  !important;
  border-color: v-bind(borderColor) !important;
  border-width: v-bind(borderWidth) !important;
  border-radius: 50px;
  justify-content: center;
  display: inline-flex;

}

.katalist-button-text {
  color: #111;
  font-size: 16px;
  font-weight: v-bind(fontWeight);
  font-family: 'Fold Grotesque Pro Medium', sans-serif;
  letter-spacing: 0.15cap;
  word-wrap: break-word;
}
</style>
