const options = [
  {
    visibleForTiers: ['Essential'],
    title: 'Essential plan',
    price: '$19',
    priceBefore: '$39.00',
    pricePeriod: '3 months for 2 users',
    discount: '50%',
    features: [
      '500 image credits / month',
      'All creative features',
      '2 users',
      '200 Video Credits (soon)',
      '20 Project Limit',
      '1 Custom Character',
      '20 Panels per Project',
      'ZIP export'
    ],
    yearly: {
      annualPriceBefore: '$348.00',
      annualPrice: '$174',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Individual'],
    title: 'Pro plan',
    price: '$89',
    discount: '10%',
    priceBefore: '$99.00',
    pricePeriod: '3 months for 2 users',
    features: [
      'Unlimited image generation',
      'All creative features',
      '2 users',
      'ZIP export',
      'Unlimited Video Credits (soon)',
      'Unlimited projects',
      '10 Custom Characters',
      '200+ panels per project',
      'PPT, Video Export'
    ],
    yearly: {
      annualPriceBefore: '$948.00',
      annualPrice: '$853',
      annualPriceText: 'Billed Annually'
    }
  },
  {
    visibleForTiers: ['Business'],
    title: 'Business plan',
    price: '$449',
    discount: '10%',
    priceBefore: '$499.00',
    pricePeriod: '3 months for 5 users',
    features: [
      'Unlimited image generation',
      'All creative features',
      'Unlimited image credits',
      'Unlimited video credits',
      'Unlimited projects',
      '25 custom characters',
      '200+ panels per project',
      'ZIP export',
      'PPT, Video export',
      '5 users',
      'Real-time collaboration'
    ],
    yearly: {
      annualPriceBefore: '$4788.00',
      annualPrice: '$4309',
      annualPriceText: 'Billed Annually'
    }
  }
]

export const getOption = (userTier: string) => {
  return options.find(option => option.visibleForTiers.includes(userTier))
}
