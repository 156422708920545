/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'

const darkTheme = {
  dark: true,
  colors: {
    background: '#111111',
    primary: '#FF9ECF',
    // storyBoard: '#21163f',
    // surface: '#FFFFFF',
    // storyButton: '#180f34',
    // 'primary-darken-1': '#3700B3',
    secondary: '#B1D37F'
    // 'secondary-darken-1': '#018786',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00'
  }
}

const lightTheme = {
  dark: false,
  colors: {
    background: '#F6F1EE'
    // storyBoard: '#FFFFFF',
    // surface: '#FFFFFF',
    // primary: '#3700B3',
    // storyButton: '#180f34',
    // 'primary-darken-1': '#3700B3',
    // secondary: '#03DAC6',
    // 'secondary-darken-1': '#018786',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00'
  }
}

const entranceTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF'
    // storyBoard: '#FFFFFF',
    // surface: '#FFFFFF',
    // primary: '#3700B3',
    // storyButton: '#180f34',
    // 'primary-darken-1': '#3700B3',
    // secondary: '#03DAC6',
    // 'secondary-darken-1': '#018786',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00'
  }
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'darkTheme',
    themes: {
      darkTheme,
      lightTheme,
      entranceTheme
    },
    aliases,
    sets: {
      fa,
      mdi
    }
  }
})
