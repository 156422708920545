import supabase from '@/supabase'
import { User } from '@supabase/supabase-js'

async function getUser (): Promise<User> {
  const { data } = await supabase.auth.getSession()

  if (!data || !data.session) {
    throw new Error('User not logged in')
  }

  return data.session.user
}

async function isUserLoggedIn (): Promise<boolean> {
  const { data } = await supabase.auth.getSession()

  if (!data || !data.session) {
    return false
  }

  return true
}

async function getAuthToken (): Promise<string> {
  const { data } = await supabase.auth.getSession()

  if (!data || !data.session) {
    throw new Error('User not logged in')
  }

  return data.session.access_token
}

export { getUser, getAuthToken, isUserLoggedIn }
